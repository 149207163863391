<template>
  <div class="empty-box" :class="{ 'borer-box': border }">
    <img :src="emptyImgMap[type]" alt="" srcset="" />
    <p>{{ tip }}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "EmptyPage",
  props: {
    type: {
      type: String,
      default: "1",
    },
    tip: {
      type: String,
      default: "暂无数据",
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyImgMap: {
        1: require("@/assets/image/empty-img/no-list-data.png"),
        2: require("@/assets/image/empty-img/no-resource-data.png"),
        3: require("@/assets/image/empty-img/no-search-data.png"),
        4: require("@/assets/image/empty-img/no-select-data.png"),
      },
    };
  },
};
</script>

<style lang="less" scoped>
.empty-box {
  text-align: center;
  padding: 40px 0;
  img {
    width: 200px;
    height: 120px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: rgba(0, 0, 0, 0.45);
  }
}
.borer-box {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}
</style>
