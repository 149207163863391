<template>
  <section class="out-stock-wrap">
    <!-- 搜索模块 start -->
    <section class="form">
      <div class="form-item">
        <span class="label">城市仓：</span>
        <el-select
          placeholder="选择城市仓"
          v-model="form.storeLogisticsId"
          @change="onChangeCityStore"
          :filter-method="(val) => onFilterMethod('cityStore', val)"
          @visible-change="(val) => onChangeVisible('cityStore', val)"
          filterable
          clearable
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item">
        <span class="label">自提点：</span>
        <el-select
          placeholder="选择自提点"
          v-model="form.storeDeliveryId"
          @change="onHandleSearch"
          :filter-method="(val) => onFilterMethod('selfTake', val)"
          @visible-change="(val) => onChangeVisible('selfTake', val)"
          filterable
          clearable
        >
          <el-option
            v-for="item in selfTakeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item">
        <span class="label">供货日期：</span>
        <el-date-picker
          v-model="supplyTime"
          @change="changeDate"
          align="right"
          type="date"
          placeholder="选择供货日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div class="form-item">
        <!-- <el-button
          slot="suffix"
          type="primary"
          size="medium"
          icon="el-icon-search"
          @click="onHandleSearch"
          >查询</el-button
        > -->
        <el-button
          slot="suffix"
          type="warning"
          size="medium"
          icon="el-icon-refresh"
          @click="onRefresh"
          >重置</el-button
        >
      </div>
      <div class="form-item right" v-if="isShowBtn">
        <el-button type="primary" size="medium" @click="onHandleFull"
          >车已满</el-button
        >
        <el-button type="warning" size="medium" @click="onHandleWithout"
          >未发车</el-button
        >
      </div>
    </section>
    <!-- 搜索模块 end -->

    <!-- 列表数据 start -->
    <section class="content" v-loading="loading">
      <div class="content-top"></div>
      <div class="content-content">
        <table-list v-if="total" :dataList="dataList"></table-list>
        <!-- 分页 start -->
        <el-pagination
          v-if="total"
          background
          @size-change="onHandleSizeChange"
          @current-change="onHandleCurrentChange"
          :current-page="pageParams.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <!-- 分页 end -->

        <!-- 缺省 start -->
        <c-empty-page v-else tip="暂无数据"></c-empty-page>
        <!-- 缺省 end -->
      </div>
    </section>
    <!-- 列表数据 end -->
  </section>
</template>

<script>
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { formatDate } from "@/utils//tools/date.js";
import TableList from "./modules/table-list";
import CEmptyPage from "@/components/common/CEmptyPage";
import {
  getTruckingOutOfStockRecord,
  postSetTruckingOfFull,
  postSetTruckingWithout,
} from "@/api/sheet-to-review/index";
// import mock from "./mock";

export default {
  name: "SheetOutStock",
  data() {
    return {
      loading: false,
      // 表单查询项
      supplyTime: "",
      form: {
        storeLogisticsId: "",
        storeDeliveryId: "",
        supplyTime: "",
      },
      // 城市仓列表
      cityStoreList: [],
      // 自提点列表
      selfTakeList: [],
      // 时间范围
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      total: 0,
      pageParams: {
        page: 1,
        pageSize: 10,
      },
      // 克隆一份初始参数，用于重置数据
      formClone: {},
      pageParamsClone: {},
      dataList: [],
    };
  },
  computed: {
    /**
     * 是否展示操纵按钮
     * 1. 存在供货日期
     * 2. 存在城市仓或自提点
     */
    isShowBtn() {
      let res =
        this.form.supplyTime &&
        (this.form.storeLogisticsId || this.form.storeDeliveryId);
      return res;
    },
  },
  components: {
    TableList,
    CEmptyPage,
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formClone = cloneDeep(this.form);
      this.pageParamsClone = cloneDeep(this.pageParams);
      this.getAjaxCityStoreList();
      this.onHandleSearch();
    },
    /**
     * 查询数据
     */
    onHandleSearch() {
      this.dataList = [];
      this.total = 0;
      this.getAjaxSheetOutStockLog();
    },
    /**
     * 获取城市仓列表
     */
    getAjaxCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckLogisticslist(params);
        this.cityStoreList = data.data;
        console.log("ajx getAjaxCityStoreList", data.data);
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    }, 500),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      if (!logisticsId || !this.form.storeLogisticsId) {
        return;
      }
      const params = {
        name,
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckDeliverylist(params);
        this.selfTakeList = data.data;
        console.log("ajx getAjaxSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    },
    500),
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // console.log("🆒 onChangeCityStore 666666666", data, this.cityStoreList);
      // 重置自提点
      this.selfTakeList = [];
      this.form.storeDeliveryId = "";
      // 获取城市仓名称
      // this.cityStoreName = this.cityStoreList.find(
      //   (item) => item.id === data
      // )?.name;
      // if (data) {
      const params = {
        name: "",
        logisticsId: data,
      };
      this.getAjaxSelfTakeList(params);
      this.onHandleSearch();
    },
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.getAjaxCityStoreList(data);
      }
      if (type === "selfTake") {
        const params = {
          name: data,
          logisticsId: this.form.storeLogisticsId,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.getAjaxCityStoreList();
      }
      if (!data && type === "selfTake") {
        const params = {
          name: "",
          logisticsId: this.form.storeLogisticsId,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 日期发生了变化
     */
    changeDate(data) {
      this.form.supplyTime = data && formatDate(data);
      this.onHandleSearch();
      console.log("🆒 changeDate data", data);
    },
    /**
     * 获得操作记录
     */
    async getAjaxSheetOutStockLog() {
      this.loading = true;
      const params = { ...this.form, ...this.pageParams };
      try {
        const { data } = await getTruckingOutOfStockRecord(params);
        console.log("ajax getTruckingOutOfStockRecord", data);
        const { total, data: list } = data;
        this.total = total;
        this.dataList = list;
      } catch (err) {
        // 模拟数据
        // const { total, page, pageSize, list } = mock;
        // this.total = total;
        // this.pageParams = {
        //   page,
        //   pageSize,
        // };
        // this.dataList = list.concat([...list]);
        console.log("ajax getTruckingOutOfStockRecord err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.pageParams.page = 1;
      this.pageParams.pageSize = val;
      this.onHandleSearch();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.pageParams.page = val;
      this.onHandleSearch();
    },
    /**
     * 重置表单数据
     */
    onRefresh() {
      this.supplyTime = "";
      this.selfTakeList = [];
      this.form = {
        ...this.formClone,
      };
      this.pageParams = {
        ...this.pageParamsClone,
      };
      this.onHandleSearch();
    },
    /**
     * 车已满
     */
    onHandleFull() {
      this.$confirm("未品控的将被操作缺货 是否确认？", "提示", {
        customClass: "jn-confirm-message-style",
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        iconClass: "el-icon-warning",
      })
        .then(() => {
          this.ajaxPostSetTruckingOfFull();
        })
        .catch(() => {});
    },
    /**
     * 未发车
     */
    onHandleWithout() {
      this.$confirm("未品控的将被操作缺货 是否确认？", "提示", {
        customClass: "jn-confirm-message-style",
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        iconClass: "el-icon-warning",
      })
        .then(() => {
          this.ajaxPostSetTruckingWithout();
        })
        .catch(() => {});
    },
    /**
     * 车已满-接口请求
     */
    ajaxPostSetTruckingOfFull: debounce(async function () {
      const params = { ...this.form };
      try {
        const { data } = await postSetTruckingOfFull(params);
        console.log("ajax postSetTruckingOfFull", data);
        this.$message.success("操作成功~");
      } catch (err) {
        console.log("ajax postSetTruckingOfFull err", err);
      }
    }, 500),
    /**
     * 未发车-接口请求
     */
    ajaxPostSetTruckingWithout: debounce(async function () {
      const params = { ...this.form };
      try {
        const { data } = await postSetTruckingWithout(params);
        console.log("ajax postSetTruckingWithout", data);
        this.$message.success("操作成功~");
      } catch (err) {
        console.log("ajax postSetTruckingWithout err", err);
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.out-stock-wrap {
  height: calc(100% - 12px);
  padding: 6px;
  display: flex;
  flex-direction: column;
  .form {
    display: flex;
    gap: 20px;
    padding: 15px;
    background: #fff;
    &-item {
      .label {
        padding-right: 5px;
      }
      /deep/ .el-select {
        width: 240px;
        height: 36px;
        .el-input {
          width: 100%;
          height: 100%;
          &__inner {
            width: 100%;
            height: 100% !important;
          }
        }
      }
      &.right {
        margin-left: 30px;
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    // padding-top: 30px;
    overflow: hidden;
    background: #fff;
    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      // padding-top: 30px;
      overflow: hidden;
    }
  }
  .el-pagination {
    padding-top: 15px;
  }
}
</style>
