<template>
  <section class="modules-wrap">
    <div class="title">操作记录</div>
    <!-- table start -->
    <div class="table">
      <el-table
        :data="dataList"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        height="100%"
        border
      >
        <el-table-column label="序号" type="index" width="90" align="center">
        </el-table-column>
        <el-table-column
          label="城市仓"
          prop="storeLogisticsName"
          minWidth="200"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="自提点"
          prop="storeDeliveryName"
          minWidth="200"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="供货日期"
          minWidth="100"
          prop="supplyTime"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="类型"
          prop="operationType"
          minWidth="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="操作时间"
          prop="operationTime"
          minWidth="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="操作人"
          prop="operator"
          minWidth="100"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- table end -->
  </section>
</template>

<script>
export default {
  name: "TableList",
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.modules-wrap {
  flex: 1;
  overflow: hidden;
  .title {
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 550;
  }
  .table {
    height: calc(100% - 50px);
  }
}
</style>
