import { BASE } from "../index";
import axios from "../http";
import { Message } from 'element-ui';
import url from "url";

const BASE_URL = BASE.PRO2;
const TDC_URL = BASE.PRO4;

/**
 * 装车单打印-详情
 * @param {*} params
 */
export const getGoodsSheetInfo = (params = {}) => {
  return axios.get(`${BASE_URL}/order/entruck/new/print`, { params });
};

/**
 * 装车单打印-列表
 * @param {*} params
 */
// export const getGoodsSheetList = (params = {}) => {
//   return axios.post(`${BASE_URL}/goods/sheet/list`, params);
// }

/**
 * 装车单打印-打印
 * @param {Object} params
 * @param {Function} callback 回调函数
 */
export const getGoodsSheetPrint = (params = {}, callback) => {
  // return axios.get(`${BASE_URL}/order/entruck/new/printEd`, { params });
  // let url = `${BASE_URL}/order/entruck/new/printEd`;
  let url = `${BASE_URL}/order/entruck/printEd`;
  let token = sessionStorage.getItem("token");
  if (!token) {
    Message.error("账号登录异常");
    return;
  }
  let query = `supplierOrderNo=${params.supplierOrderNo}&token=${token}`;
  window.open(`${url}?${query}`);
  // 执行回调操作
  callback && callback();
}

/**
 * 跟车单打印-打印
 * @param {*} params
 */
export const getCartSheetPrint = (params = {}) => {
  return axios.post(`${TDC_URL}/qc/follow_car`, params);
}


/**
 * 装车单复核-详情
 * @param {*} params
 */
export const getGoodsSheetReviewInfo = (params = {}) => {
  let url = '/entruck/getQcSkuList';
  return axios.post(`${BASE_URL}${url}`, params);
};

/**
 * 装车单复核-列表
 * @param {*} params
 */
// export const getGoodsSheetReviewList = (params = {}) => {
//   return axios.post(`${BASE_URL}/goods/sheet/review/list`, params);
// }

/**
 * 装车单复核-单个品类-详情+列表
 * @param {*} params
 */
export const getGoodsSheetReviewSingleList = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/getQcSkuInfo`, params);
};

/**
 * 装车单复核-单个品类-复核确认
 * @param {*} params
 */
export const postGoodsSheetSingleReview = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/setQcOutOfStock`, params);
};

/**
 * 装车单复核-自提点-单品-缺货原因列表
 */
export const getGoodsSheetQCExplainList = (params = {}) => {
    return axios.post(`${BASE_URL}/entruck/getQcReasonList`, params);
  }

  /**
   * 装车单复核-自提点-单品-缺货原因列表-全部
   */
  export const getGoodsSheetQCExplainListAll = (params = {}) => {
    return axios.post(`${BASE_URL}/entruck/getQcReasonListAll`, params);
  }


/**
 * 装车单复核-整个供货单-缺货列表
 * @param {*} params
 */
export const getGoodsSheetOutNumber = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/getOutStockList`, params);
};

/**
 * 装车单复核-整个供货单-复核确认
 * @param {*} params
 */
export const postGoodsSheetAllReview = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/setQcConfirm`, params);
};

/**
 * 装车单复核-整单缺货（整单缺货按钮隐藏，已废弃）
 * @param {*} params
 */
export const postGoodsSheetAllLose = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/setAllOutOfStock`, params);
};

/**
 * 装车单复核-撤回
 * @param {*} params
 */
export const postGoodsSheetRevocation = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/cancelQcConfirm`, params);
};

/**
 * 品控复核记录列表
 * @param {*} params
 */
export const qualityControlReview = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/getEntruckQcList`, params);
};

/**
 * 品控复核 一键复核
 * @param {*} params
 */
export const getOneClickReview = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/setSupplyOrderOutOfStock`, params);
};

/**
 * 集配-发车缺货-操作记录
 * @param {*} params
 */
export const getTruckingOutOfStockRecord = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/getTruckingOutOfStockRecord`, params);
};

/**
 * 集配-发车缺货-车已满
 * @param {*} params
 */
export const postSetTruckingOfFull = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/setTruckingOfFull`, params);
};

/**
 * 集配-发车缺货-未发车
 * @param {*} params
 */
export const postSetTruckingWithout = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/setTruckingWithout`, params);
};

/**
 * 集配后台-标签打印-打印全部标签
 * @param {Object} params
 */
export const postPrintBatchOfOrder = (params = {}) => {
  return axios.post(`${BASE_URL}/order/entruck/label/batch/print`, params);
};

/**
 * 集配后台-标签打印-打印单个标签
 * @param {Object} params
 */
export const postPrintOfOrder = (params = {}) => {
  return axios.post(`${BASE_URL}/order/entruck/label/print`, params);
};

/**
 * 集配后台-装车单打印-打印原因
 * @param {Object} params
 */
export const postPrintLogOfOrder = (params = {}) => {
  return axios.post(`${BASE_URL}/order/entruck/add/print/log`, params);
};

/**
 * 集配后台-标签打印记录-列表
 * @param {Object} params
 */
export const getPrintLabelLogOfOrder = (params = {}) => {
  return axios.get(`${BASE_URL}/order/entruck/label/print/log`, { params });
};

/**
 * 集配后台-标签打印记录-列表-导出
 * @param {Object} params
 */
export const getPrintLabelLogOfOrderExport = (params = {}) => {
  let path = `${BASE_URL}/order/entruck/label/print/log/export`;
  let token = sessionStorage.getItem("token");
  if (!token) {
    Message.error("账号登录异常");
    return;
  }
  const query = { ...params, token };
  delete query.page;
  delete query.pageSize;
  const queryData = url.format({ query });
  console.log('🆒 getPrintLabelLogOfOrderExport', path, queryData);
  window.open(path + queryData);
  // return axios.get(`${BASE_URL}/order/entruck/label/print/log/export`, { params });
};

/**
 * 集配后台-供货单打印记录-列表
 * @param {Object} params
 */
export const getPrintLogOfOrder = (params = {}) => {
  return axios.get(`${BASE_URL}/order/entruck/print/log`, { params });
};

/**
 * 集配后台-供货单打印记录-列表-导出
 * @param {Object} params
 */
export const getPrintLogOfOrderExport = (params = {}) => {
  let path = `${BASE_URL}/order/entruck/print/log/export`;
  let token = sessionStorage.getItem("token");
  if (!token) {
    Message.error("账号登录异常");
    return;
  }
  const query = { ...params, token };
  delete query.page;
  delete query.pageSize;
  const queryData = url.format({ query });
  console.log('🆒 getPrintLogOfOrderExport', path, queryData);
  window.open(path + queryData);
  // return axios.get(`${BASE_URL}/order/entruck/print/log/export`, { params });
};

