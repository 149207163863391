var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "modules-wrap" }, [
    _c("div", { staticClass: "title" }, [_vm._v("操作记录")]),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.dataList,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              height: "100%",
              border: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "序号",
                type: "index",
                width: "90",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "城市仓",
                prop: "storeLogisticsName",
                minWidth: "200",
                "show-overflow-tooltip": "",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "自提点",
                prop: "storeDeliveryName",
                minWidth: "200",
                "show-overflow-tooltip": "",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "供货日期",
                minWidth: "100",
                prop: "supplyTime",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "类型",
                prop: "operationType",
                minWidth: "100",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作时间",
                prop: "operationTime",
                minWidth: "100",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作人",
                prop: "operator",
                minWidth: "100",
                align: "center",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }